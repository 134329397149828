import React from "react";

const LanguageRow = ({ src, text }) => {
  return (
    <>
      <img src={src} alt={text} style={{ width: "20px", height: "20px", marginRight: "5px" }} />
      <span style={{ color: "white", marginRight: "3px" }}>{text}</span>
    </>
  );
};

export default LanguageRow;