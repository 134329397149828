import React from "react";

/** Localization */
import { pages } from "../../localization/local";

/** Assets */
import facility from "../../assets/img/facility.jpg";

const Services = ({ language }) => {
  if (!language) {
    language = "hu";
  }

  const strings = pages.services;
  return (
    <>
      <h1>{strings.title[language]}</h1>
      <header className="services-header mb-3" style={{ display: "flex" }}>
        <div className="img-container">
          <img src={facility} alt="facility" />
        </div>
        <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          {
            strings.header[language].map((serviceName, index) => {
              return <li key={index}>{serviceName}</li>;
            })
          }
        </ul>
      </header>
      <div>
        <ol>
          {
            strings.content.items.map((item, index) => {
              return (
                <li key={index} style={{ fontSize: "2rem", fontWeight: "500" }}>
                  <h2>{item.title[language]}</h2>
                  <ul style={{ fontSize: "1rem", fontWeight: "normal" }}>
                    {
                      item.tasks[language].map((task, index) => {
                        return <li key={index}>{task}</li>
                      })
                    }
                  </ul>
                </li>
              )
            })
          }
        </ol>
      </div>
    </>
  )
}

export default Services;