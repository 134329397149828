/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import logo from "../../assets/img/logo.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./index.scss";

import Introduction from "../introduction/Introduction";
import Services from "../services/Services";
import Contact from "../contact/Contact";

/**Components */
import BusinessCard from '../../components/businessCard/BusinessCard';
import Map from '../../components/map/Map';
import LatestPosts from '../../components/latestPosts/LatestPosts';

/** Assets */
import flagHu from "../../assets/svg/hu.svg";
import flagEn from "../../assets/svg/en.svg";
import data_protection from "../../assets/pdf/dataprotection.pdf"

/** Localization */
import { pages } from "../../localization/local";
import LanguageRow from '../../components/languageRow/LanguageRow';

function Index() {
  const savedLanguage = localStorage.getItem("language");
  const [language, setLanguage] = useState(savedLanguage ? savedLanguage : "hu");
  const handleSetLanguage = (language) => (event) => {
    setLanguage(language);
    localStorage.setItem("language", language);
  }

  const renderLanguage = (language) => {
    return language === "en" ?
      <LanguageRow src={flagEn} text="English" /> :
      <LanguageRow src={flagHu} text="Magyar" />
  }

  const strings = pages.main;
  return (
    <Router>
      <Navbar className="navbar-custom" expand="lg">
        <Navbar.Brand href="/" style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} className="mr-2" style={{ width: "40px", height: "40px" }} alt="logo" />
          <span className="nav-link-react">Zöld-KARIKA Kft.</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto justify-content-end">
            {
              strings.links.map((link, index) => {
                return <Link key={index} className="nav-link nav-link-react" to={link.location}>{link[language]}</Link>
              })
            }
            <NavDropdown title={renderLanguage(language)} id="basic-nav-dropdown">
              <li className="dropdown-item" onClick={handleSetLanguage("hu")}>
                <img src={flagHu} alt="flag hu" style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                <span>Magyar</span>
              </li>
              <li className="dropdown-item" onClick={handleSetLanguage("en")}>
                <img src={flagEn} alt="flag en" style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                <span>English</span>
              </li>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="mt-3 mb-3 mr-auto ml-auto main-container">
        <div className="border rounded mr-3 card-body content soft-shadow">
          <Switch>
            <Route exact path="/">
              <Introduction language={language} />
            </Route>
            <Route exact path="/szolgaltatasok">
              <Services language={language} />
            </Route>
            <Route exact path="/elerhetosegeink">
              <Contact language={language} />
            </Route>
          </Switch>
        </div>

        <div className="widgets-container">
          <BusinessCard language={language} />
          <LatestPosts language={language} />
          <Map language={language} />
        </div>
      </div>

      <div className="p-3 mb-3 mr-auto ml-auto card-body border footer soft-shadow">
        <footer className="text-center" style={{ fontSize: "0.75rem" }}>
          <p style={{ margin: 0 }}>Copyright © {new Date().getFullYear()} Zöld-KARIKA Kft. Minden jog fenntartva!</p>
          <a href={data_protection}>Adatkezelési nyilatkozat</a>
        </footer>
      </div>
    </Router>
  );
}

export default Index;
