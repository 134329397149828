export const pages = {
  main: {
    links: [
      {
        location: "/",
        page: "Introduction",
        hu: "Bemutatkozás",
        en: "Introduction",
      },
      {
        location: "/szolgaltatasok",
        page: "Services",
        hu: "Szolgáltatások",
        en: "Services",
      },
      {
        location: "/elerhetosegeink",
        page: "Contact",
        hu: "Elérhetőségeink",
        en: "Contacts",
      },
    ]
  },
  introduction: {
    title: {
      hu: "Bemutatkozás",
      en: "Introduction"
    },
    welcome: {
      hu: "Üdvözöljük honlapunkon!",
      en: "Welcome to our website!",
    },
    paragraphs: {
      hu: [
        "Cégünk 1995 óta foglalkozik társasházak kezelésével. Jelenleg is számos budapesti társasház képviseletét látjuk el. Ügyfeleink között megtalálhatóak a kis, 4-5 lakásos társasházaktól kezdve a nagyobb, 80-100 lakásos házakig. Vállalkozásunk főleg Budapest VIII. és IX.  kerületében kezel társasházakat, de vannak házaink a VII., XI, XXI. kerületekben is.",

        "Cégünk vezetőinek több, mint 30 éves tapasztalata van társasházkezelői területen.Hisszük, hogy a megújulás nagyon fontos ezen a területen is, ezért folyamatosan fejlesztjük, modernizáljuk vállalkozásunkat",

        "Partnereink között megtalálható több jogász és műszaki szakember is, hogy a felmerülő problémákat minél gyorsabban meg tudjuk oldani.Nagyon jó kapcsolatokat ápolunk a kerületi Önkormányzatokkal, mert fontosnak tartjuk a meghirdetett felújítási pályázatokon való részvételt.",

        "Cégünk 5.000.000 forintos szakmai felelősségbiztosítással rendelkezik.Minden társasházkezelő szakemberünk regisztrálva van és rendelkezik OKJ vizsgával.",

        "Bármi kérdése van, forduljon hozzánk bizalommal!",
      ],
      en: [
        "Our company has been dealing with facility management since 1995. We still take care of representation of many condominiums in Budapest. Among our clients we can find from small 4-5-apartment condominiums to bigger 80-100-apartment condominiums. The main territories of our business are the 8th and 9th district of Budapest, but we have clients in the 7th, 11th, and in the 21st district too.",

        "Our Leaders have more than 30 years of experience in facility management. We believe it is important to be able to renew in this area too, so we are continuosly developing, modernizing our company.",

        "We have partnership with multiple lawyers and technical experts to be able to solve possible problems as soon as possible. We have quite good relationship with the district governments because we consider important to participate in renovation tenders.",

        "Our company has a HUF5.000.000 of liability insurance. Every facility manager experts in our team are registered and they have OKJ exam.",

        "If you have any questions, feel free to contact us!"
      ],
    }
  },
  info: {

  },
  services: {
    title: {
      hu: "Szolgáltatások",
      en: "Services"
    },
    header: {
      hu: [
        "Társasházak könyvelése",
        "Pályázatok figyelése, benyújtása",
        "Projektmenedzsment",
        "Kettős aláírás alkalmazása",
        "Kiemelt hátralék kezelés",
        "Folyamatos műszaki felügyelet",
      ],
      en: [
        "Bookkeeping for condominiums",
        "Monitoring tenders and apply for them",
        "Project management",
        "Double signature",
        "Priority arrears treatment",
        "Continuous technical supervision"
      ],
    },
    content: {
      items: [
        {
          title: {
            hu: "A társasház működése és üzemeltetése",
            en: "Operation and maintenance of the condominium",
          },
          tasks: {
            hu: [
              "A társasház közgyűlésén a tulajdonosok által elfogadott költségvetés alapján működtetjük a társasházat.",
              "Elvégeztetjük a szükséges hibaelhárítási karbantartási munkákat. Sürgős esetekben munkaidő utáni és hétvégi telefonos ügyelettel állunk rendelkezésünkre.",
              "Figyelemmel kísérjük a társasházak részére kiírt pályázatokat (Fővárosi, kerületi stb.). A pályázati lehetőségekről tájékoztatást adunk, és közgyűlési határozat alapján, elkészítjük azokat.",
              "Saját kivitelezőkkel nem rendelkezünk, de megbízható vállalkozókkal állunk kapcolatban. A felújítási, karbantartási és hibaelhárítási feladatokat, előzetesen a Számvizsgáló Bizottság által (felújítás esetén a közgyűlés által) jóváhagyott árajánlat alapján külső kivitelezőkkel, egyéni vállalkozókkal, szerződéssel végeztetjük. Ez alól kivétel az azonnali beavatkozást igénylő vízszerelés, elektromos meghibásodás, vihar okozta kár stb.",
              "Ellátjuk a társasház dolgozója felett a munkáltatói feladatok (Munkaszerződés megkötése, feladat ellátásának ellenőrzése, munkabér számfejtése és kifizetése, feladat ellátásnak ellenőrzése, havi elektronikus bevallás készítése.)",
              "Kifizetjük a társasház közüzemi, vállalkozói és egyéb számláit. A kifizetések a társasház számlájáról két aláírással történnek. Első aláíró a cégünk által megjelölt tagok valamelyike, a második aláíró a társasház Számvizsgáló Bizottság egyik tagja.",],
            en: [
              "We operate the condominium based on the budget approved by owners on the general meetings.",
              "We get the required troubleshooting and maintenance works done. In emergency cases we offer phone duty after working hours and weekend days",
              "We are monitoring tenders for condominiums (metropolitan, district, etc.). We provide information about tender possibilities and based on general meeting decisions we create the tenders.",
              "We don't have own constructors, but we have relationship with trustworthy contractors. The bids of the renovation, maintenance and troubleshooting consts are getting supervised by the Audit Committee (in case of renovation by the general meeting) and based on that we get those job done by outsider contractors, individual contractors, with having contracts every time. Only exceptions are the plumbing work that are required to intervened immediately, electricity faults, damage by storm, etc.",
              "We supply the employer tasks above the employees of the condominium (such as sign contracts, work supervision, payroll tasks and payment, creation of monthly tax return electronically).",
              "We pay the utility-, business-, and other bills of the condominium. Payments from the account of the condominium happens with two signatures. The first signatory is on of our chosen representative colleague, the second signatory is a member of the Audit Committee.",
            ],
          }
        },
        {
          title: {
            hu: "A társasház pénzügyi feladatai",
            en: "Finance tasks of the condominium",
          },
          tasks: {
            hu: [
              "A társasház pénzügyi forgalmát folyamatosan kezeljük (10 millió Forintot meghaladó bevétel esetén mérlegképes könyvelő bevonása szükséges). Cégünk az „Eház” felhő alapú könyvelési programmal dolgozik, amely lehetővé teszi a Számvizsgáló Bizottság tagjainak a teljes körű betekintést a társasház könyvelésébe, amennyiben e-mail címmel rendelkeznek. A Tulajodnostársak pedig betekinthetnek saját egyenlegükbe.  A közös költség befizetését kiemelten figyeljük a hátralékos tulajdonosokkal szemben az SZMSZ szerint járunk el. A tartósan nem fizetők ellen ügyvéd bevonásával megtesszük a törvényes intézkedést. (fizetési meghagyástól a végrehajtásig).",
              "Gondoskodunk a társadalombiztosítási és adó kötelezettség teljesítéséről, a bevallásról a NAV elektronikus ügyfélkapun keresztül.",
              "A havonkénti kimutatásokat, bizonylatokat, minden törvényes adatszolgáltatást a hatóságok számára rendezetten, ellenőrzésre alkalmas módon tárolunk.",
              "Fűtéselszámolás elkészítése az éves beszámolóban."
            ],
            en: [
              "We are handling the financial flows of the condominium continuosly (Above HUF10.000.000 income a Certifiec Accountant is required). Our company works a cloud-based software \"Eház\" which fully provides transparency to the bookkeeping of the condominium for the members of the Audit Committee if they have an email address. Also owners can check their own balance. We pay close attention on the payment of the common cost, against the overdue customers we follow the Organizational and Operational Rules (OOR). Against the permanently non-payers we involve lawyers to make the necessary legal actions (from payment order to execution).",
              "We take care of social insurance and tax fulfillments and return via the \"NAV elektronikus ügyfélkapu\".",
              "Monthly statements, certificates and every lawful data provision are stored sorted and able to be inspected by authorities.",
              "We create heating bills in the Annual Financial Report.",
            ],
          }
        },
        {
          title: {
            hu: "A társasház éves rendes közgyűlését a törvény által megadott határidőig összehívjuk.",
            en: "We organize the Annual General Meeting of the condominium by the deadline given by law",
          },
          tasks: {
            hu: [
              "A közgyűlés határozatainak megfelelően működtetjük a társasházat.",
              "Elkészítjük a társasház éves költségvetését, karbantartási tervét. A pénzügyi beszámolót és költségvetési tervet a társasház Számvizsgáló Bizottságának ellenőrzésével és véleményezésével állítjuk össze. A jóváhagyott költségvetés alapján folyamatosan figyeljük a tervezett és tényleges kiadásokat, lényeges eltérés esetén értesítjük a Számvizsgáló Bizottságot."
            ],
            en: [
              "We follow the decisions of the general meeting when operating condominium",
              "We create the annual budget of the condominium and maintenance plan. The financial report and budget is made with the inspection and advising of the Audit Committee. Based on the approved budget we are monitoring the planned and actual expenditures, in case of substancial difference we notify the Audit Committe.",
            ],
          }
        },
      ]
    }
  },
  contact: {
    title: {
      hu: "Elérhetőségeink",
      en: "Contacts",
    },
    phoneName: {
      hu: "Telefonszám",
      en: "Phone",
    },
    items: [
      {
        name: "Földesi Éva",
        jobTitle: {
          hu: "Ügyvezető",
          en: "Executive Director",
        },
        phone: "+36/30-914-7330",
        email: "zoldkarika@zoldkarika.eu",
        id: "feva",
      },
      {
        name: "Fábián-Petróczy Borbála",
        jobTitle: {
          hu: "Ügyvezető",
          en: "Director",
        },
        // phone: "+36/30-464-2029",
        // email: "bori@zoldkarika.eu",
        id: "fpborbala",
      },
      {
        name: "Patus Józsefné",
        jobTitle: {
          hu: "Társasházkezelő",
          en: "Facility manager",
        },
        phone: "+36/30-326-7833",
        email: "marcsi@zoldkarika.eu",
        id: "pjozsefne",
      },
      {
        name: "Horváthné Frigy Anikó",
        jobTitle: {
          hu: "Társasházkezelő",
          en: "Facility manager",
        },
        phone: "+36/30-464-0860",
        email: "aniko@zoldkarika.eu",
        id: "hfaniko",
      },
      {
        name: "Petróczy Bálint",
        jobTitle: {
          hu: "Társasházkezelő, Műszaki szakértő",
          en: "Facility manager, Technical expert",
        },
        phone: "+36/30-350-9595",
        email: "balint@zoldkarika.eu",
        id: "pbalint",
      },
      {
        name: "Verebes Tibor",
        jobTitle: {
          hu: "Társasházkezelő, Műszaki szakértő",
          en: "Facility manager, Technical expert",
        },
        phone: "+36/30-587-0265",
        email: "tibor@zoldkarika.eu",
        id: "vtibor",
      },
      {
        name: "Petróczy Mátyás",
        jobTitle: {
          hu: "Társasházkezelő",
          en: "Facility manager",
        },
        phone: "+36/30-732-7267",
        email: "matyas@zoldkarika.eu",
        id: "pmatyas",
      },
      {
        name: "Verschnitzer Sándorné",
        jobTitle: {
          hu: "Titkárság vezető",
          en: "Head of Secretariat",
        },
        phone: "+36/1-218-6549, +36/1-215-5507",
        email: "iroda@zoldkarika.eu",
        id: "vsandorne",
      },
    ]
  }
}

export const components = {
  map: {
    title: {
      hu: "Térkép",
      en: "Map",
    }
  },
  businessCard: {
    title: {
      hu: "Elérhetőségek",
      en: "Contacts",
    },
    contacts: {
      hu: [
        "Cím: 1094 Budapest, Páva utca 31.",
        "Ügyfélszolgálat: H-CS: 8-16h",
        "Telefon: +36/1-218-6549; +36/1-215-5507",
        "Ügyelet (0-24h): +36/30-914-7330",
        "Email: zoldkarika@zoldkarika.eu",
      ],
      en: [
        "Address: 1094 Budapest, Páva utca 31.",
        "Customer service: M-Th: 8am-4pm",
        "Phone: +36/1-218-6549; +36/1-215-5507",
        "On call (0-24h): +36/30-914-7330",
        "Email: zoldkarika@zoldkarika.eu",
      ],
    }
  },
  latestPosts: {
    title: {
      hu: "Legutóbbi bejegyzések",
      en: "Latest posts",
    }
  }
}