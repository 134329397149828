import React from "react";

/** Localization */
import { pages } from "../../localization/local";

/** Components */
import { Card } from "react-bootstrap";

/** Imgs */
import vsandorne from "../../assets/img/vsandorne.jpg";
import feva from "../../assets/img/feva.jpg";
import fpborbala from "../../assets/img/fpborbala.jpg";
import pbalint from "../../assets/img/pbalint.jpg";
import pjozsefne from "../../assets/img/pjozsefne.jpg";
import pmatyas from "../../assets/img/pmatyas.jpg";
import vtibor from "../../assets/img/vtibor.jpg";
import hfaniko from "../../assets/img/hfaniko.jpg";
import placeholder from "../../assets/img/placeholder.jpg";

const images = {
  vsandorne,
  feva,
  fpborbala,
  hfaniko,
  pbalint,
  pjozsefne,
  pmatyas,
  vtibor,
  placeholder,
}

const Contact = ({ language }) => {
  const strings = pages.contact;
  if (!language) {
    language = "hu";
  }
  return (
    <>
      <h1 className="mb-3">{strings.title[language]}</h1>
      <div style={{ width: "100%", height: "300px" }} className="mb-3">
        <img style={{ objectFit: "cover", width: "100%", height: '100%' }} alt="map" src="//maps.googleapis.com/maps/api/staticmap?key=AIzaSyB9p2d11RcS56mFucaq5y-rLA-rYyAT1B0&amp;scale=1&amp;format=png&amp;zoom=15&amp;size=900x300&amp;language=en&amp;maptype=roadmap&amp;markers=size%3Adefault%7Ccolor%3A0xff0000%7Clabel%3AA%7CBudapest+Z%C3%B6ld-Karika+Kft&amp;center=Budapest+Z%C3%B6ld-Karika+Kft&amp;visual_refresh=true" />
      </div>
      <div className="contact-container">
        {
          strings.items.map((person, index) => {
            return (
              <Card key={index} style={{ width: "100%" }}>
                <Card.Img loading="lazy" variant="top" src={images[person.id] || images.placeholder} />
                <Card.Body>
                  <Card.Title>{person.name}</Card.Title>
                  <Card.Text>{person.jobTitle[language]}</Card.Text>
                  {
                    person.phone &&
                    <Card.Text>{strings.phoneName[language]}: {person.phone}</Card.Text>
                  }
                  {
                    person.email &&
                    <Card.Text>Email: {person.email}</Card.Text>
                  }
                </Card.Body>
              </Card>
            );
          })
        }
      </div>
    </>
  )
}

export default Contact;