import React from "react";

import { Card } from "react-bootstrap";

import { components } from "../../localization/local";

const BusinessCard = ({ language }) => {
  const strings = components.businessCard;
  if (!language) {
    language = "en";
  }
  return (
    <Card className="mb-3 widget-1 soft-shadow">
      <Card.Body>
        <Card.Title id="title">{strings.title[language]}</Card.Title>
        <ul style={{ margin: 0, padding: 0, listStyleType: "none" }}>
          {
            strings.contacts[language].map((contact, index) => {
              return <li key={index}>{contact}</li>;
            })
          }
        </ul>
      </Card.Body>
    </Card>
  );
}

export default BusinessCard;