import React, { useEffect } from "react";

/** Localization */
import { components } from "../../localization/local";
const LatestPosts = ({ language }) => {
  const strings = components.latestPosts;
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }, []);

  return (
    <section className="twitterContainer mb-3 widget-2 soft-shadow border rounded" style={{ overflow: "hidden", padding: "20px", backgroundColor: "white" }}>
      <h5>{strings.title[language]}</h5>
      <div className="twitter-embed">
        <a
          className="twitter-timeline"
          data-theme="light"
          data-tweet-limit="3"
          data-chrome="noheader nofooter"
          data-lang={language}
          href="https://twitter.com/zoldkarikakft"
        >
          Tweets by HeyMarkKop
        </a>
      </div>
    </section>
  );
};

export default LatestPosts;