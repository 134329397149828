import React from "react";

/** Localization */
import { pages } from "../../localization/local";

/** Assets */
import team1 from "../../assets/img/team1.jpg";

const Introduction = ({ language }) => {
  const strings = pages.introduction;
  if (!language) {
    language = "hu";
  }
  return (
    <>
      <h1 id="title">{strings.title[language]}</h1>
      <div className="fit-image-container mb-3">
        <img src={team1} alt="team_indoor" style={{ borderRadius: "0.25rem" }} />
      </div>
      <h2 id="welcome" className="mb-3">{strings.welcome[language]}</h2>
      <div>
        {
          strings.paragraphs[language].map((paragraph, index) => {
            return <p key={index}>{paragraph}</p>
          })
        }
      </div>
    </>
  );
}

export default Introduction;