import React from "react";

import { Card } from "react-bootstrap";

import { components } from "../../localization/local";

const Map = ({ language }) => {
  if (!language) {
    language = "hu";
  }
  const strings = components.map;
  return (
    <Card className="mb-3 widget-3 soft-shadow">
      <Card.Body>
        <Card.Title>{strings.title[language]}</Card.Title>
        <a href="//www.google.com/maps?ll=47.482665,19.072863&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=18067618610201780179" rel="noopener noreferrer" target="_blank">
          <div style={{ width: "100%", height: "300px" }} className="mb-3">
            <img style={{ objectFit: "cover", width: "100%", height: '100%' }} alt="map" src="//maps.googleapis.com/maps/api/staticmap?key=AIzaSyB9p2d11RcS56mFucaq5y-rLA-rYyAT1B0&amp;scale=1&amp;format=png&amp;zoom=15&amp;size=350x350&amp;language=en&amp;maptype=roadmap&amp;markers=size%3Adefault%7Ccolor%3A0xff0000%7Clabel%3AA%7CBudapest+Z%C3%B6ld-Karika+Kft&amp;center=Budapest+Z%C3%B6ld-Karika+Kft&amp;visual_refresh=true" />
          </div>
        </a>
      </Card.Body>
    </Card>
  );
}

export default Map;